import React from "react";
import {
    Container,
    Jumbotron
} from "react-bootstrap";
import {
    Fade
} from "react-reveal";
import {
    CategoryPageContext,
    CategoryPageManager,
    CategoryPageProducts
} from "@shopcrate/shopcrate-framework";

import background from "../../img/jumbotron-background.jpg";

function CategoryPage(props) {
    return (
        <CategoryPageManager { ...props }>
            { props.helmet && (
                <CategoryPageContext.Consumer>
                    { categoryContext => props.helmet(categoryContext) }
                </CategoryPageContext.Consumer>
            )}
            <Jumbotron fluid
                       className="jumbotron-hero mb-0"
                       style={ {
                           backgroundImage: `url(${ background })`,
                           overflow: "hidden"
                       } }>
                <div className="hero-mobile-fader">
                    <Container className="text-center">
                        <Fade right duration={2000}>
                            <h1>
                                VIND JOUW NIEUWE
                                <br/>
                                FAVO <span className="text-primary">KAMERPLANT</span>
                            </h1>
                            <h3 className="allura allura-big text-muted">
                                eat. sleep. buy plants. repeat.
                            </h3>
                        </Fade>
                    </Container>
                </div>
            </Jumbotron>
            <div className="bg-primary py-3 mb-5">
                <div className="container text-center amaranth" style={{ color: "#404B35" }}>
                    premium quality | altijd direct vers van de kweker | CO<sub>2</sub> neutraal | gratis verzending
                </div>
            </div>
            <div className="container mb-5">
                <CategoryPageProducts/>
            </div>
        </CategoryPageManager>
    )
}

export default React.memo(CategoryPage);
