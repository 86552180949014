import React from "react";
import { withRouter } from "react-router-dom";

import Error404 from "./404";

import AlocasiaPortadoraPatrick from "./content-pages/AlocasiaPortadoraPatrick";
import AraliaPolysciasFabian from "./content-pages/AraliaPolysciasFabian";
import ArecaDypsisArjan from "./content-pages/ArecaDypsisArjan";
import DracaenaReflexaDanny from "./content-pages/DracaenaReflexaDanny";
import FicusLyrata from "./content-pages/FicusLyrata";
import HomelomenaMaggie from "./content-pages/HomelomenaMaggie";
import Monstera from "./content-pages/Monstera";
import PhoenixRoebelliniRuby from "./content-pages/PhoenixRoebelliniRuby";
import StrelitziaNicolaiSterre from "./content-pages/StrelitziaNicolaiSterre";
import YuccaJoris from "./content-pages/YuccaJoris";

function PlantPages(props) {
    switch(props.match.params.plantCodeName) {
        case "alocasia-portadora-patrick": return ( <AlocasiaPortadoraPatrick/> )
        case "aralia-polyscias-fabian": return ( <AraliaPolysciasFabian/> )
        case "areca-dypsis-arjan": return ( <ArecaDypsisArjan/> )
        case "dracaena-reflexa-danny": return ( <DracaenaReflexaDanny/> )
        case "ficus-lyrata": return ( <FicusLyrata/> )
        case "homelomena-maggie": return ( <HomelomenaMaggie/> )
        case "monstera": return ( <Monstera/> )
        case "phoenix-roebellini-ruby": return ( <PhoenixRoebelliniRuby/> )
        case "strelitzia-nicolai-sterre": return ( <StrelitziaNicolaiSterre/> )
        case "yucca-joris": return ( <YuccaJoris/> )
        default: return ( <Error404/> )
    }
}

export default withRouter(PlantPages);
