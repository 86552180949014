import React from "react";
import ContentPage from "../../components/product-content-page/ContentPage";

import topBannerImage from "../../img/product-content-pages/dracaena-reflexa-danny/topBanner.jpg";
import kenmerkenImage from "../../img/product-content-pages/dracaena-reflexa-danny/kenmerken.jpg";

function DracaenaReflexaDanny() {
    return (
        <ContentPage
            productIds={[20]}
            topBannerImage={ topBannerImage }
            title="Dracaena Reflexa Danny"
            shortName="Reflexa"
            topDescription="De Dracaena, ook wel de Drakenbloedboom genoemd, is een makkelijk te verzorgen kamerplant! Hierdoor is de Dracaena perfect geschikt als kantoorplant. Je herkent hem aan de langwerpige, spitse bladeren met roze, gele of witte strepen over de lengte van het blad. Eén ding is zeker: de Dracaena staat prachtig in elk interieur!"
            kenmerken={[
                "Tropische spitse bladeren",
                "Meerdere vertakte stammen",
                "Schaduwplant",
                "Makkelijk in de verzorging"
            ]}
            kenmerkenImage={ kenmerkenImage }
            specifications={{
                "geschikt voor": "thuis & kantoor",
                "soort plant": "Dracaena Reflexa",
                "herkomst": "Afrika, Azië, Midden-Amerika",
                "houdt van": "weinig licht/water",
                "maat & hoogte": "pot Ø 34 - 140 cm"
            }}
            verzorging={{
                "water geven": "De Dracaena heeft pas water nodig als de grond droog aanvoelt. Vooral in de zomer komt dit wat vaker voor. In de winter is elke 4 tot 6 weken water geven voldoende en in de zomer elke 2 tot 3 weken. Als de Dracaena te veel water krijgt is dit te zien aan het blad dat bruine vlekken en gele randen krijgt.",
                "sproeien": "Sproeien is geen noodzaak, maar wel goed om de plant gezond, ongedierte- en stofvrij te houden.",
                "voeden": "Voeden is alleen nodig in het hoogseizoen, vanaf maart. Na zo’n 6 tot 8 weken is de voeding verbruikt en kun je opnieuw voeden.  Bekijk voor de juiste dosering de verpakking van de kamerplantenvoeding.",
                "verpotten": "Verpotten doe je het beste in de lente of direct na aanschaf. Vervolgens raden we aan eens per 2 jaar te verpotten in een pot van 20% groter.",
                "standplaats": "Dracaena’s kunnen prima overleven op weinig licht en staan dus helemaal op hun plekje voor een raam op het noorden. Als de plant lichter kleurt of geel wordt, is dit een teken dat er te veel licht bij komt. Als hij niet of langzaam groeit, betekent dit dat hij te weinig licht ontvangt."
            }}
            bottomBannerImage={ topBannerImage }
        />
    );
}

export default React.memo(DracaenaReflexaDanny);
