import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Container,
    Jumbotron
} from "react-bootstrap";
import {
    Fade
} from "react-reveal";
import {
    ShopContext
} from "@shopcrate/shopcrate-framework";

import categoryId from "../variables/categoryId";

import webshopBackground from "../../img/top_10_kantoorplanten.jpg";
import aboutUsBackground from "../../img/calltoaction2.jpg";

function HomeJumbotron() {
    const shopContext = useContext(ShopContext);

    return (
        <Jumbotron
            fluid
            className="jumbotron-hero mb-0"
            style={{
                backgroundColor: "#808B75",
                overflow: "hidden"
            }}
        >
            <div style={{
                paddingTop: "10vh",
                paddingBottom: "10vh"
            }}>
                <Container>
                    <Fade right duration={2000}>
                        <h1 className="h1-home text-white text-center mb-5">
                            LIFE IS SHORT. BUY PLANTS.
                        </h1>
                    </Fade>
                    <div className="row">
                        <div className="col-md mb-3">
                            <Fade up duration={2000}>
                                <Link to={ "/category/" + categoryId }>
                                    <div className="d-flex align-items-center justify-content-center" style={{
                                        background: `url(${webshopBackground})`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        height: "35vh",
                                        minHeight: "200px"
                                    }}>
                                        <Link to={ "/category/" + categoryId } className="btn btn-primary btn-home-dark-green mt-2">
                                            { shopContext.shop && shopContext.shop.orderingEnabled ? (
                                                "Naar de webshop"
                                            ) : (
                                                "Naar het assortiment"
                                            )}
                                            <i className="fas fa-angle-right ml-2"/>
                                        </Link>
                                    </div>
                                </Link>
                            </Fade>
                        </div>
                        <div className="col-md mb-3">
                            <Fade up duration={2000}>
                                <Link to="/over-ons">
                                    <div className="d-flex align-items-center justify-content-center" style={{
                                        background: `url(${aboutUsBackground})`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        height: "35vh",
                                        minHeight: "200px"
                                    }}>
                                        <Link to="/over-ons" className="btn btn-primary btn-home-white mt-2">
                                            Interieurbeplanting
                                            <i className="fas fa-angle-right ml-2"/>
                                        </Link>
                                    </div>
                                </Link>
                            </Fade>
                        </div>
                    </div>
                </Container>
            </div>
        </Jumbotron>
    );
}

export default HomeJumbotron;
