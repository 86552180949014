import React from "react";
import ContentPage from "../../components/product-content-page/ContentPage";

import topBannerImage from "../../img/product-content-pages/alocasia-portadora-patrick/topBanner.jpg";
import kenmerkenImage from "../../img/product-content-pages/alocasia-portadora-patrick/kenmerken.jpg";
import bottomBannerImage from "../../img/product-content-pages/alocasia-portadora-patrick/bottomBanner.jpg";

function AlocasiaPortadoraPatrick() {
    return (
        <ContentPage
            productIds={[18]}
            topBannerImage={ topBannerImage }
            title="Alocasia Portadora Patrick"
            shortName="Patrick"
            topDescription="De kamerplant Alocasia Portadora is echt een opvallende plant! De Alocasia wordt ook wel Taro, Reuzentaro of Olifantsoor genoemd en dit is niet zonder reden. De bladeren van deze plant kunnen namelijk wel één meter lang worden. Gunstig aan deze kamerplant is dat hij makkelijk in onderhoud is."
            kenmerken={[
                "Opvallende kamerplant",
                "Grote gekartelde bladeren",
                "Tropische sfeerbrenger"
            ]}
            kenmerkenImage={ kenmerkenImage }
            specifications={{
                "geschikt voor": "thuis",
                "soort plant": "Alocasia Portadora",
                "herkomst": "Zuid-Oost Azië",
                "houdt van": "licht, warmte & water",
                "maat & hoogte": "pot Ø 34 - 140 cm"
            }}
            verzorging={{
                "water geven": "De grond van deze kamerplant houd je altijd licht vochtig, maar niet met de wortels onder water. Geef de Alocasia daarom regelmatig een beetje water.",
                "sproeien": "De Alocasia komt uit een tropisch gebied, waardoor hij gewend is aan hoge luchtvochtigheid. Zo’n één keer per week sproeien wordt daarom aangeraden. ",
                "voeden": "In de zomer groeit de Alocasia hard waardoor extra voeding geen overbodige luxe is. Geef hem 1 x per maand plantenvoeding om deze groei te voeden. In de winter stopt de plant met groeien en is voeden niet nodig.",
                "verpotten": "De Alocasia heeft potentie om erg groot te worden. Als dit gebeurt, hebben de wortels wel steeds meer ruimte nodig en moet hij dus verpot worden. Dit is nodig na steeds zo’n 2 jaar.",
                "standplaats": "Plaats de Alocasia op een plek waar veel zonlicht is, zodat hij zeker zo’n vijf uur zon krijgt. Een plekje voor het raam op het zuiden is het meest ideaal! Ondanks dat de plant van warmte houdt, kun je hem het beste wel weghouden bij de verwarming of kachel."
            }}
            bottomBannerImage={ bottomBannerImage }
        />
    );
}

export default React.memo(AlocasiaPortadoraPatrick);
