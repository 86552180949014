import React from "react";
import {
    Container,
    Jumbotron
} from "react-bootstrap";

import assortimentPdf from "../../assets/plantenplaza-brochure.pdf";

class AssortimentBrochure extends React.Component {
    state = {
        show: false
    }

    render() {
        return (
            <Jumbotron
                fluid
                className="mb-0"
                style={{
                    backgroundColor: "white",
                    paddingTop: "7rem",
                    paddingBottom: "7rem",
                    overflow: "hidden"
                }}
            >
                <Container>
                    <iframe
                        src={ assortimentPdf }
                        className="d-none d-md-block"
                        title="Brochure"
                        style={{ width: "100%", height: "600px", border: "none" }}
                    />
                    <div className="text-center mt-3 mb-3">
                        <a href={ assortimentPdf } target="_blank" rel="noreferrer noopener" className="btn btn-primary btn-lg">
                            Download PDF
                        </a>
                    </div>
                </Container>
            </Jumbotron>
        );
    }
}

export default AssortimentBrochure;
