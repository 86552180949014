import React from "react";
import {
    Container
} from "react-bootstrap";

import bannerImage from "./../img/top_10_kantoorplanten.jpg";

import beaucarnea1Image from "./../img/top-5-kantoorplanten/beaucarnea/beaucarnea1.jpg";
import beaucarnea2Image from "./../img/top-5-kantoorplanten/beaucarnea/beaucarnea2.jpg";
import beaucarnea3Image from "./../img/top-5-kantoorplanten/beaucarnea/beaucarnea3.jpg";

import dracaena1Image from "./../img/top-5-kantoorplanten/dracaena/dracaena1.jpg";
import dracaena2Image from "./../img/top-5-kantoorplanten/dracaena/dracaena2.jpg";
import dracaena3Image from "./../img/top-5-kantoorplanten/dracaena/dracaena3.jpg";

import philondendron1Image from "./../img/top-5-kantoorplanten/philondendron/philondendron1.jpg";
import philondendron2Image from "./../img/top-5-kantoorplanten/philondendron/philondendron2.jpg";
import philondendron3Image from "./../img/top-5-kantoorplanten/philondendron/philondendron3.jpg";

import sanseveria1Image from "./../img/top-5-kantoorplanten/sanseveria/sanseveria1.jpg";
import sanseveria2Image from "./../img/top-5-kantoorplanten/sanseveria/sanseveria2.jpg";
import sanseveria3Image from "./../img/top-5-kantoorplanten/sanseveria/sanseveria3.jpg";

import yucca1Image from "./../img/top-5-kantoorplanten/yucca/yucca1.jpg";
import yucca2Image from "./../img/top-5-kantoorplanten/yucca/yucca2.jpg";
import yucca3Image from "./../img/top-5-kantoorplanten/yucca/yucca3.jpg";
import CallToAction from "../components/CallToAction";

function Top5Image(props) {
    return (
        <div className="col-md-4">
            <img src={ props.image } alt={ props.alt } className="rounded img-fluid"
                 style={{ border: props.best ? "3px solid #AABF55" : "" }}/>
        </div>
    );
}

class Top5 extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <div className="jumbotron jumbotron-fluid jumbotron-header" style={ { backgroundImage: "url(" + bannerImage + ")" } }>
                    <div className="container">
                        <h1 className="text-white">Top 5 Kantoorplanten</h1>
                    </div>
                </div>
                <Container>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h5 className="card-title">1. Dracaena</h5>
                            <p className="card-text">
                                Deze plant kan een hoop hebben. Heeft weinig water nodig en sommige soorten hebben niet
                                veel
                                licht nodig.
                            </p>
                            <h6>Plantenplaza favoriet: Dracaena Compacta</h6>
                            <p className="card-text">
                                Deze veilige keuze zal u zeker een hoop plezier geven zonder al teveel omkijken.
                            </p>
                            <div className="row">
                                <Top5Image image={ dracaena1Image } alt="Dracaena" best/>
                                <Top5Image image={ dracaena2Image } alt="Dracaena"/>
                                <Top5Image image={ dracaena3Image } alt="Dracaena"/>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <div className="card-body">
                            <h5 className="card-title">2. Sansevieria</h5>
                            <p className="card-text">
                                Deze onverwoestbare plant heeft zijn oorsprong in de woestijn. Hij staat ook wel bekend
                                als de
                                vrouwentong. Het verzorgen van deze plant is heel gemakkelijk en hij is er in vele
                                vormen en
                                maten.
                            </p>
                            <h6>Plantenplaza favoriet: Sanseveria Mikado</h6>
                            <p className="card-text">
                                Deze sterke en trendy plant zal het overal goed doen!
                            </p>
                            <div className="row">
                                <Top5Image image={ sanseveria2Image } alt="Sansevieria" best/>
                                <Top5Image image={ sanseveria1Image } alt="Sansevieria"/>
                                <Top5Image image={ sanseveria3Image } alt="Sansevieria"/>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <div className="card-body">
                            <h5 className="card-title">3. Yucca</h5>
                            <p className="card-text">
                                Deze plant komt voor in de meest droge gebieden ter wereld. Je kan makkelijk een keer
                                vergeten
                                water te geven.
                            </p>
                            <h6>Plantenplaza favoriet: Yucca Elephantipes</h6>
                            <p className="card-text">
                                Deze sterke plant is ideaal als kantoorplant en er zit een hoge sierwaarde aan.
                            </p>
                            <div className="row">
                                <Top5Image image={ yucca3Image } alt="Yucca" best/>
                                <Top5Image image={ yucca1Image } alt="Yucca"/>
                                <Top5Image image={ yucca2Image } alt="Yucca"/>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <div className="card-body">
                            <h5 className="card-title">4. Beaucarnea</h5>
                            <p className="card-text">
                                Deze sterke en sierlijke woestijn plant ook wel de olifantenpoot genoemd is direct te
                                herkennen
                                aan zijn grote knol. Qua verzorging is dit een van ideale kantoorplanten.
                            </p>
                            <h6>Plantenplaza favoriet: Beaucarnea recuvata</h6>
                            <div className="row">
                                <Top5Image image={ beaucarnea3Image } alt="Beaucarnea" best/>
                                <Top5Image image={ beaucarnea1Image } alt="Beaucarnea"/>
                                <Top5Image image={ beaucarnea2Image } alt="Beaucarnea"/>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">5. Philondendron</h5>
                            <p className="card-text">
                                Deze sierlijke compacte plant vraagt niet veel verzorging. Deze designplant past in elk
                                interieur en doet het ook goed in een kamer waar weinig licht is. Ze zijn er vele vormen
                                en
                                maten, van hangplanten tot een plant in een pot.
                            </p>
                            <h6>Plantenplaza favoriet: Philondendron Xanadu</h6>
                            <div className="row">
                                <Top5Image image={ philondendron3Image } alt="Philondendron" best/>
                                <Top5Image image={ philondendron2Image } alt="Philondendron"/>
                                <Top5Image image={ philondendron1Image } alt="Philondendron"/>
                            </div>
                        </div>
                    </div>

                    <CallToAction/>
                </Container>
            </React.Fragment>
        );
    }
}

export default Top5;
