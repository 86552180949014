import React from "react";
import ContentPage from "../../components/product-content-page/ContentPage";

import topBannerImage from "../../img/product-content-pages/aralia-polyscias-fabian/topBanner.jpg";
import kenmerkenImage from "../../img/product-content-pages/aralia-polyscias-fabian/kenmerken.jpg";

function AraliaPolysciasFabian() {
    return (
        <ContentPage
            productIds={[21, 22, 23]}
            productNames={["Fabricio", "Fabiaan", "Faabe"]}
            topBannerImage={ topBannerImage }
            title="Aralia Polyscias Fabian"
            shortName="Fabian"
            topDescription="De Aralia Polyscias is een kamerplant voor de wat meer ervaren plantenouder. Hij kenmerkt zich door de groene of paarse bladeren en stevige takken. De plant geeft veel schaduw, wat ook in de naam zit. Poly betekent namelijk veel in het Grieks en Scias betekent schaduw."
            kenmerken={[
                "Vertakte stammen",
                "Unieke donkergroene bladeren",
                "Moderne uitstraling",
                "Schaduwrijke plant"
            ]}
            kenmerkenImage={ kenmerkenImage }
            specifications={{
                "geschikt voor": "thuis & kantoor",
                "soort plant": "Aralia Polyscias",
                "herkomst": "Afrika & Azië",
                "houdt van": "licht, warmte & water",
                "maat & hoogte": "pot Ø 27/34 - 120/175 cm"
            }}
            verzorging={{
                "water geven": "In de zomer staat de Polyscias graag in een vochtige grond zodat deze niet uitdroogt. Regelmatig water geven is dus nodig. In de winter kun je beter pas water geven wanneer de grond iets droog aanvoelt. Bovendien geeft de Polyscias de voorkeur aan regenwater boven kraanwater, vanwege het kalk in kraanwater.",
                "sproeien": "Regelmatig sproeien doet de Polyscias goed, hij houdt namelijk van een hoge luchtvochtigheid. Een teken van een te lage luchtvochtigheid is dat de plant gele blaadjes krijgt.",
                "voeden": "De Polyscias heeft alleen voeding nodig in de lente en zomer. Je geeft deze plant vloeibare voeding voor groene kamerplanten.",
                "verpotten": "Je verpot deze plant het beste in de lente of meteen na heet aanschaffen en daarna eens per 2 jaar. Zorg dat de nieuwe pot zo’n 20% groter is.",
                "standplaats": "De Polyscias krijgt het liefst een paar uur direct zonlicht per dag. Dichtbij een raam is dus perfect! Verplaats de Polyscias niet vaak van plek, daar houdt hij niet zo van. Mocht er bladval optreden (vooral in de winter), betekent dit dat de plant te weinig licht krijgt."
            }}
            bottomBannerImage={ topBannerImage }
        />
    );
}

export default React.memo(AraliaPolysciasFabian);
