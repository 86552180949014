import React from "react";
import VizSensor from "react-visibility-sensor";
import { CardDeck } from "react-bootstrap";
import { Fade } from "react-reveal";

class ReferenceDeck extends React.Component {
    state = {
        show: false
    }
    render() {
        return (
            <VizSensor
                partialVisibility
                minTopValue={100}
                onChange={(isVisible) => {
                    this.setState({ show: isVisible ? true : this.state.show });
                }}
            >
                <Fade up when={ this.state.show }>
                    <CardDeck>
                        { this.props.children }
                    </CardDeck>
                </Fade>
            </VizSensor>
        )
    }
}

export default ReferenceDeck;
