import React from "react";
import background from "../../img/references/background.jpg";
import { Container, Jumbotron } from "react-bootstrap";
import { Fade } from "react-reveal";

function ReferencesJumbotron() {
    return (
        <Jumbotron fluid
                   className="jumbotron-hero mb-0"
                   style={ {
                       backgroundImage: `url(${ background })`,
                       overflow: "hidden"
                   } }>
            <div className="hero-mobile-fader">
                <Container>
                    <Fade right duration={2000}>
                        <h1 style={ { fontSize: "3.5rem", fontWeight: 900 } }>
                            EEN BLIJE KLANT IS
                            <br/>
                            EEN BLIJE <span className="text-primary">PLANT</span>
                        </h1>
                    </Fade>
                </Container>
            </div>
        </Jumbotron>
    )
}

export default ReferencesJumbotron;
