import React, {
    useContext
} from "react";
import Skeleton from "react-loading-skeleton";
import {
    ProductPageContext
} from "@shopcrate/shopcrate-framework";

function ProductSubtitle() {
    const productContext = useContext(ProductPageContext);
    return (
        <p>
            { productContext.product ? productContext.product.subtitle : (
                <Skeleton/>
            )}
        </p>
    )
}

export default React.memo(ProductSubtitle);
