import React from "react";
import Error404 from "../404";
import Helmet from "../../components/Helmet";
import ProductContent from "../../components/shop/ProductContent";

function Product() {
    return (
        <ProductContent
            error404Page={ <Error404/> }
            helmet={ (productContext) => (
                <Helmet
                    title={ productContext.product ? productContext.product.name : null }
                    description={ productContext.product ? productContext.product.subtitle : null }
                />
            )}
        />
    );
}

export default React.memo(Product);
