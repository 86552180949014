import React from "react";

function FloatingWhatsApp() {
    return (
        <a
            href="https://web.whatsapp.com/send?phone=31647907267"
            className="btn btn-primary text-white d-none d-md-block"
            target="_blank"
            rel="noopener noreferrer"
            style={{
                backgroundColor: "#25D366",
                borderColor: "#25D366",
                position: "fixed",
                zIndex: "3000",
                bottom: 20,
                left: 20,
                boxShadow: "0 1px 6px 0 rgba(0,0,0,0.06), 0 2px 32px 0 rgba(0,0,0,0.16)"
            }}
        >
            <i className="fab fa-whatsapp mr-1"/> Kunnen we je helpen?
        </a>
    )
}

export default FloatingWhatsApp;
