import React from "react";
import {
    Link
} from "react-router-dom";
import {
    ProductCollectionBarInternal,
    ProductImage,
    withShopContext
} from "@shopcrate/shopcrate-framework";

class PlantenPlazaProductCollectionBar extends ProductCollectionBarInternal {
    getProductCard(product) {
        const {
            shopContext
        } = this.props;
        return (
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 d-flex flex-column">
                <div className="flex-grow-1">
                    <Link to={ "/product/" + product.id + "/" + product.url }>
                        <div className="d-flex justify-content-center align-content-center rounded" style={{ height: "120px", backgroundColor: "white" }}>
                            <ProductImage
                                image={ product ? product.images[0] : null }
                                size="175x130"
                                className="img-fluid rounded"
                                alt="Product"
                                style={{ objectFit: "contain" }}
                            />
                        </div>
                        <h6 className="mt-2">{ product.name }</h6>
                    </Link>
                </div>
                { shopContext.shop.showPricing && (
                    <p className="mb-0">
                        <b>&euro; { product.price.toFixed(2).replace(".", ",") }</b>
                    </p>
                )}
                <p className="text-success">
                    <i className="fas fa-check-circle mr-2"/>
                    { shopContext.shop.showPricing ? (
                        <b>In 7 dagen thuis</b>
                    ) : (
                        <b>Neem contact op</b>
                    )}
                </p>
            </div>
        )
    }
}

export default withShopContext(PlantenPlazaProductCollectionBar);
