import React from "react";
import {
    Helmet
} from "react-helmet";

import AboutUsJumbotron from "../components/about-us/AboutUsJumbotron";
import AboutUsSustainable from "../components/about-us/AboutUsSustainable";
import AboutUsWhatWeDo from "../components/about-us/AboutUsWhatWeDo.";
import AboutUsCallToAction from "../components/about-us/AboutUsCallToAction";

class AboutUs extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Over ons - PlantenPlaza</title>
                </Helmet>
                <AboutUsJumbotron/>
                <AboutUsSustainable/>
                <AboutUsWhatWeDo/>
                <AboutUsCallToAction/>
            </React.Fragment>
        );
    }
}

export default AboutUs;
