import React from "react";
import { Card } from "react-bootstrap";

function Reference(props) {
    return (
        <Card className="mb-5" style={ {
            borderRight: "none",
            borderTop: "none",
            borderRadius: 0,
            borderWidth: "5px",
            borderColor: "#F5F5F6"
        } }>
            <Card.Body className="pl-5 pr-5">
                <p>
                    { props.children }
                </p>
                <div style={ {
                    height: "75px",
                    display: "inline-flex",
                    alignItems: "center"
                } }>
                    { props.logo && (
                        <img src={ props.logo } style={ {
                            maxHeight: "75px",
                            maxWidth: "100px"
                        } } alt={ props.name } className="mr-3"/>
                    ) }
                    <b className="text-primary">{ props.name.toUpperCase() }</b>
                </div>
            </Card.Body>
        </Card>
    )
}

export default Reference;
