import React from "react";
import VizSensor from "react-visibility-sensor";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";
import {
    Link
} from "react-router-dom";
import {
    Fade
} from "react-reveal";

// import image1 from "../../img/about-us/what-we-do-1.jpg";
// import image2 from "../../img/about-us/what-we-do-2.jpg";
// import image3 from "../../img/about-us/what-we-do-3.jpg";

class AboutUsWhatWeDo extends React.Component {
    state = {
        show: false
    }

    render() {
        return (
            <Jumbotron
                fluid
                className="mb-0"
                style={ {
                    backgroundColor: "#808B75",
                    paddingTop: "4rem",
                    paddingBottom: "4rem",
                    overflow: "hidden"
                }}
            >
                <Container>
                    <VizSensor
                        partialVisibility
                        minTopValue={ 200 }
                        onChange={ (isVisible) => {
                            this.setState({ show: isVisible ? true : this.state.show });
                        } }
                    >
                        <Row>
                            <Col md={ 6 } className="mb-3" style={ { color: "white" } }>
                                <Fade left when={ this.state.show }>
                                    <h1 className="h1-big text-primary">
                                        WAT WIJ DOEN
                                    </h1>
                                </Fade>
                                <Fade up when={ this.state.show }>
                                    <p className="mt-4 text-white">
                                        Planten kunnen van elke ruimte een sfeervolle en gezonde omgeving maken, mits ze
                                        goed geplaatst worden. Dit kan lastiger zijn dan je denkt, bijvoorbeeld omdat je
                                        in een kantoorruimte te maken kunt hebben met te donkere of niet-optimale
                                        omstandigheden voor planten. Wij adviseren, plaatsen en onderhouden
                                        interieurbeplanting, zodat onze klant volledig wordt voorzien in de groene
                                        wensen. Wij komen vrijblijvend langs om de locatie te inspecteren en te
                                        adviseren welke planten het best tot hun recht komen in jouw (kantoor)ruimte. Zo
                                        garanderen wij dat je zo lang mogelijk plezier hebt van de geplaatste planten!
                                    </p>
                                    <ul className="mt-4 fa-ul" style={ {
                                        paddingLeft: "24px",
                                        marginLeft: 0
                                    } }>
                                        <li className="mt-3">
                                            <span className="fa-li"><i className="fas fa-check"/></span>
                                            Vrijblijvende locatie-inspectie & passend advies
                                        </li>
                                        <li className="mt-3">
                                            <span className="fa-li"><i className="fas fa-check"/></span>
                                            Jouw eigen "groenplan" op maat
                                        </li>
                                        <li className="mt-3">
                                            <span className="fa-li"><i className="fas fa-check"/></span>
                                            Plaatsen van de geschikte planten & periodiek onderhoud
                                        </li>
                                        <li className="mt-3">
                                            <span className="fa-li"><i className="fas fa-check"/></span>
                                            Altijd de beste kwaliteit planten tegen een scherpe prijs
                                        </li>
                                    </ul>
                                    <Link to="/contact" className="btn btn-primary mt-3">Ontvang vrijblijvend advies</Link>
                                </Fade>
                            </Col>
                            {/*<Col md={ 6 } className="d-none d-md-block">*/}
                            <Col md={ 6 }>
                                <Fade right when={ this.state.show }>
                                    {/*<Row>*/}
                                    {/*    <Col md={ 6 }>*/}
                                    {/*        <img src={ image1 } className="img-fluid rounded mt-5" alt="Room"/>*/}
                                    {/*    </Col>*/}
                                    {/*    <Col md={ 6 }>*/}
                                    {/*        <img src={ image2 } className="img-fluid rounded" alt="Room"/>*/}
                                    {/*        <img src={ image3 } className="img-fluid rounded mt-3 pr-5" alt="Room"/>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    <div className="embed-responsive embed-responsive-16by9" style={ {
                                        marginTop: "20px",
                                        maxWidth: "560px",
                                        marginLeft: "auto",
                                        marginRight: "auto"
                                    } }>
                                        <iframe title="promotievideo" src="https://www.youtube.com/embed/5PGX5Hiya-o" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    </VizSensor>
                </Container>
            </Jumbotron>
        );
    }
}

export default AboutUsWhatWeDo;
