import React from "react";
import ContentPage from "../../components/product-content-page/ContentPage";

import topBannerImage from "../../img/product-content-pages/homelomena-maggie/topBanner.jpg";
import kenmerkenImage from "../../img/product-content-pages/homelomena-maggie/kenmerken.jpg";

function HomelomenaMaggie() {
    return (
        <ContentPage
            productIds={[27]}
            topBannerImage={ topBannerImage }
            title="Homelomena Maggie"
            shortName="Maggie"
            topDescription="De Homalomena Maggy is een echt geliefde plant door haar hartvormige groene bladeren. Hij vult een lege plek in de kamer direct op met het opvallende uiterlijk!"
            kenmerken={[
                "Mooi groot hartvormigblad met rode stengels",
                "Half schaduwplant",
                "Vult direct een plek in de kamer op"
            ]}
            kenmerkenImage={ kenmerkenImage }
            specifications={{
                "geschikt voor": "thuis & kantoor",
                "soort plant": "Homalomena",
                "herkomst": "Zuid- en Zuid-Oost Azië",
                "houdt van": "licht, warmte & water",
                "maat & hoogte": "pot Ø 34 - 150 cm"
            }}
            verzorging={{
                "water geven": "Regelmatig water geven met kalkarm regenwater is voldoende. Een schotel onder de pot kan goed zijn, zodat de grond niet kan uitdrogen.",
                "sproeien": "Sproei de Homalomena een ker per week om voor een goede luchtvochtigheid te zorgen.",
                "voeden": "De plant heeft in de lente en zomer een keer per 2 weken voeding nodig.",
                "verpotten": "Verpot de Homalomena in het voorjaa, bij voorkeur in een pot die 20% groter is zodat hij weer ruimte krijgt om verder te groeien.",
                "standplaats": "De Homalomena kan prima op een donkere plaats staan, maar de voorkeur gaat uit naar een lichte plek en een temperatuur tussen de 18 en 22 graden. Licht zorgt voor een betere groei en lichtere bladeren."
            }}
            bottomBannerImage={ topBannerImage }
        />
    );
}

export default React.memo(HomelomenaMaggie);
