import React from "react";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";
import {
    Fade
} from "react-reveal";

import background from "../../img/contact-banner.jpg";

function ContactJumbotron() {
    return (
        <Jumbotron fluid
                   className="jumbotron-hero mb-0"
                   style={ {
                       backgroundImage: `url(${ background })`,
                       overflow: "hidden"
                   } }>
            <div className="hero-mobile-fader">
                <Container>
                    <Row>
                        <Col xs="8" sm="7">
                            <Fade right duration="2000">
                                <h1>
                                    POWERED BY <span className="text-primary">PLANTS</span>
                                </h1>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Jumbotron>
    )
}

export default ContactJumbotron;
