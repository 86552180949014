import React from "react";
import {
    Helmet
} from "react-helmet";

import AssortimentJumbotron from "../components/assortiment/AssortimentJumbotron";
import AssortimentBrochure from "../components/assortiment/AssortimentBrochure";

class Assortiment extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Assortiment - PlantenPlaza</title>
                </Helmet>
                <AssortimentJumbotron/>
                <AssortimentBrochure/>
            </React.Fragment>
        );
    }
}

export default Assortiment;
