import React from "react";
import {
    ProductPageContext,
    ProductPageManager,
    ProductImageCarousel,
    ProductPageAddToCardButton,
    ProductPageDescription,
    ProductPagePrice
} from "@shopcrate/shopcrate-framework";

import ProductTitle from "./ProductTitle";
import ProductSubtitle from "./ProductSubtitle";
import ProductContentPageButton from "./ProductContentPageButton";

function ProductContent(props) {
    return (
        <ProductPageManager { ...props }>
            { props.helmet && (
                <ProductPageContext.Consumer>
                    { productContext => props.helmet(productContext) }
                </ProductPageContext.Consumer>
            )}
            <div className="container mt-5 mb-5">
                <div className="row mt-5">
                    <div className="col-md-6 mb-3 mb-md-0">
                        <div className="bg-white p-2 product-page-dropshadow h-100">
                            <ProductImageCarousel/>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3 mb-md-0">
                        <div className="bg-white text-center p-5 product-page-dropshadow h-100">
                            <ProductTitle/>
                            <div className="h1 text-primary my-4">
                                <ProductPagePrice/>
                            </div>
                            <ProductPageAddToCardButton buttonVariant="primary"/>
                            <div className="mt-4">
                                <ProductSubtitle/>
                            </div>
                            <ProductContentPageButton/>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="bg-white p-5 product-page-dropshadow h-100">
                        <h3 className="">Beschrijving</h3>
                        <ProductPageDescription/>
                    </div>
                </div>
            </div>
        </ProductPageManager>
    )
}

export default React.memo(ProductContent);
