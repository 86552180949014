import React from "react";
import ContentPage from "../../components/product-content-page/ContentPage";

import topBannerImage from "../../img/product-content-pages/areca-dypsis-arjan/topBanner.jpg";
import kenmerkenImage from "../../img/product-content-pages/areca-dypsis-arjan/kenmerken.jpg";
import bottomBannerImage from "../../img/product-content-pages/areca-dypsis-arjan/bottomBanner.jpg";

function ArecaDypsisArjan() {
    return (
        <ContentPage
            productIds={[19]}
            topBannerImage={ topBannerImage }
            title="Areca Dypsis Arjan"
            shortName="Arjan"
            topDescription="De Areca Dypsis staat ook wel bekend als de Goudpalm. Deze naam is te danken aan de geel-oranje vruchten die aan de plant groeien. De plant bestaat eigenlijk uit meerdere kleine palmpjes die samen een geheel vormen. De bladeren zijn smal geveerd en lichtgroen van kleur."
            kenmerken={[
                "Mooie grote langwerpige bladveren",
                "Sterke elegante plant",
                "Mooie opvuller voor een lege plek"
            ]}
            kenmerkenImage={ kenmerkenImage }
            specifications={{
                "geschikt voor": "thuis & kantoor",
                "soort plant": "Areca-Dypsis",
                "herkomst": "Zuid-Oost Azië",
                "houdt van": "licht, warmte & water",
                "maat & hoogte": "pot Ø 34 - 170 cm"
            }}
            verzorging={{
                "water geven": "De Arecapalm heeft het liefst een licht vochtige grond. Regelmatig water geven is dus nodig. Denk hierbij aan 1 x per week in de winter en 2 x per week in de zomer. Het water geven dient van bovenaf te gebeuren.",
                "sproeien": "Sproei de Areca bij voorkeur dagelijks, met name in de winter wanneer de verwarming aanstaat.",
                "voeden": "In de zomer groeit Arjan hard en heeft hij bij elke waterbeurt wat voeding nodig. In de herfst en winter groeit de Arecapalm niet waardoor voeden niet nodig is.",
                "verpotten": "Het beste verpot je Arjan in het voorjaar, zodat er meer vocht bij kan en hij in de zomer verder kan groeien. De nieuwe pot dient ongeveer 20% groter te zijn.",
                "standplaats": "De Areca krijgt graag licht, maar niet te veel direct zonlicht. Je plaatst hem dus het beste voor het raam op het noorden. Wanneer de bladeren geel kleuren, is dit een teken dat de plant te veel licht krijgt."
            }}
            bottomBannerImage={ bottomBannerImage }
        />
    );
}

export default React.memo(ArecaDypsisArjan);
