import React from "react";
import {
    Helmet
} from "react-helmet";
import {
    Link
} from "react-router-dom";
import {
    Container
} from "react-bootstrap";

import bannerImage from "../img/interieurbeplanting.jpg";

class Error404 extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>404 Not Found - PlantenPlaza</title>
                </Helmet>
                <div className="jumbotron jumbotron-fluid jumbotron-header" style={ { backgroundImage: "url(" + bannerImage + ")" } }>
                    <div className="container">
                        <h1 className="text-white">404 Not Found</h1>
                    </div>
                </div>
                <Container style={ { marginBottom: "500px" } }>
                    <h2>Deze pagina bestaat niet.</h2>
                    <Link to="/" className="btn btn-primary btn-lg mt-3">Terug naar de homepage</Link>
                </Container>
            </React.Fragment>
        );
    }
}

export default Error404;
