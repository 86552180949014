import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    ProductPageContext,
    ProductPageManager,
    Loading,
    ProductPagePrice,
    ShopContext
} from "@shopcrate/shopcrate-framework";

import Helmet from "../Helmet";

function ContentPage(props) {
    const shopContext = useContext(ShopContext);
    return (
        <React.Fragment>
            <Helmet title={ props.title }/>
            <div style={{
                height: "25vh",
                backgroundImage: "url(" + props.topBannerImage + ")",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}/>
            <div className="container text-center p-5">
                <h1 style={{ color: "#808B75" }}>{ props.title }</h1>
                <div style={{ maxWidth: "800px" }} className="mx-auto">
                    <p>{ props.topDescription }</p>
                </div>
            </div>
            <div className="py-5" style={{ backgroundColor: "#808B75" }}>
                <div className="container text-white">
                    <div className="row">
                        <div className="col-md-7 py-3 d-flex align-items-center">
                            <div>
                                <h1 className="h1-big text-primary mb-4">KENMERKEN</h1>
                                <ul>
                                    { props.kenmerken.map((text, index) => {
                                        return (
                                            <li key={ index }>{ text }</li>
                                        )
                                    })}
                                </ul>
                                <Link to="/vrijblijvend-advies" className="btn btn-primary mt-3 mb-2">
                                    Is { props.shortName } iets voor mij?
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <img src={ props.kenmerkenImage } alt={ props.title } className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-primary py-5">
                <div className="container">
                    <div className="row">
                        { Object.keys(props.specifications).map((key, index) => {
                            const value = props.specifications[key];
                            return (
                                <div className="col text-center" key={ index }>
                                    <h2 style={{ fontSize: "1.5rem" }}>{ key }</h2>
                                    <span style={{ fontWeight: "600", color: "#404B35" }}>{ value }</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="bg-white py-5">
                <div className="container my-5">
                    { !shopContext.shop ? (
                        <Loading/>
                    ) : props.productIds.length === 1 ? (
                        <ProductPageManager productId={ props.productIds[0] }>
                            <ProductPageContext.Consumer>
                                { value => {
                                    const product = value.product;
                                    if(!product) {
                                        return (
                                            <Loading/>
                                        )
                                    }
                                    const image = product.images[0];
                                    const url = "/product/" + product.id + "/" + product.url;
                                    return (
                                        <div className="row">
                                            <div className="col">
                                                <Link to={ url }>
                                                    <img src={ image.url } className="img-fluid" alt={ props.title }/>
                                                </Link>
                                            </div>
                                            <div className="col text-center d-flex align-items-center">
                                                <div>
                                                    <h1 className="mt-0">GEEF JIJ { props.shortName.toUpperCase() } EEN MOOI PLEKJE BIJ JOU THUIS?</h1>
                                                    { shopContext.shop.orderingEnabled ? (
                                                        <Link to={ url } className="btn btn-primary mt-3 mb-2">
                                                            Koop { props.shortName } direct
                                                        </Link>
                                                    ) : (
                                                        <Link to={ url } className="btn btn-primary mt-3 mb-2">
                                                            Neem { props.shortName }
                                                        </Link>
                                                    )}
                                                    <div className="h1 text-primary mt-3">
                                                        <ProductPagePrice/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }}
                            </ProductPageContext.Consumer>
                        </ProductPageManager>
                    ) : (
                        <div className="row d-flex">
                            { props.productIds.map((productId, index) => (
                                <ProductPageManager productId={ productId } key={ index }>
                                    <ProductPageContext.Consumer>
                                        { value => {
                                            const product = value.product;
                                            if(!product) {
                                                return (
                                                    <Loading/>
                                                )
                                            }
                                            const image = product.images[0];
                                            const url = "/product/" + product.id + "/" + product.url;
                                            return (
                                                <div className="col text-center d-flex flex-column justify-content-between flex-grow-1">
                                                    <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                                                        <Link to={ url }>
                                                            <img src={ image.url } className="img-fluid" alt={ props.title } style={{ maxHeight: "50vh"}}/>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        { shopContext.shop.orderingEnabled ? (
                                                            <Link to={ url } className="btn btn-primary mt-3 mb-2">
                                                                Koop { props.productNames[index] } direct
                                                            </Link>
                                                        ) : (
                                                            <Link to={ url } className="btn btn-primary mt-3 mb-2">
                                                                Neem { props.productNames[index] }
                                                            </Link>
                                                        )}
                                                        <div className="h1 text-primary mt-3">
                                                            <ProductPagePrice/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </ProductPageContext.Consumer>
                                </ProductPageManager>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="bg-white py-5">
                <div className="container my-5 text-center">
                    <h1 className="text-primary h1-big">
                        HOE VERZORG JE { props.shortName.toUpperCase() }?
                    </h1>
                    <div className="mx-auto" style={{ maxWidth: "800px" }}>
                        { Object.keys(props.verzorging).map((key, index) => {
                            const value = props.verzorging[key];
                            return (
                                <div key={ index } className="my-5">
                                    <h2 style={{ fontSize: "1.5rem" }}>{ key }</h2>
                                    <p>{ value }</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="py-5" style={{
                backgroundImage: "url(" + props.bottomBannerImage + ")",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}>
                <div className="container my-5 py-5">
                    <div className="row">
                        <div className="col"/>
                        <div className="col">
                            <h1 className="text-white h1-big">CAN { props.shortName.toUpperCase() } COME HOME TO YOU?</h1>
                            { shopContext.shop && shopContext.shop.orderingEnabled ? (
                                <Link to={ "/product/" + props.productId } className="btn btn-primary mt-3 mb-2">
                                    Koop { props.shortName } nu!
                                </Link>
                            ) : (
                                <Link to={ "/product/" + props.productId } className="btn btn-primary mt-3 mb-2">
                                    Neem { props.shortName }!
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(ContentPage);
