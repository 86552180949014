import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Container,
    Jumbotron
} from "react-bootstrap";
import PlantenPlazaProductCollectionBar from "../PlantenPlazaProductCollectionBar";

function HomeCollection() {
    return (
        <Jumbotron fluid
                   className="mb-0"
                   style={ {
                       backgroundColor: "white"
                   } }>
            <Container>
                <div className="text-center mb-4">
                    <h2>op zoek naar wat meer groen in je leven?</h2>
                </div>
                <PlantenPlazaProductCollectionBar collection="home-page-collection" noTitle/>
                <div className="text-center">
                    <Link to="/category/4" className="btn btn-primary">Meer planten</Link>
                </div>
            </Container>
        </Jumbotron>
    );
}

export default React.memo(HomeCollection);
