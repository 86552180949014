import React from "react";
import {
    Route,
    Switch
} from "react-router-dom";

import useTracking from "./hooks/useTracking";
import ScrollToTop from "./hooks/ScrollToTop";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import FloatingWhatsApp from "./components/FloatingWhatsApp";
import Error404 from "./pages/404";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import References from "./pages/References";
import Top5 from "./pages/Top5";
import Contact from "./pages/Contact";
import VrijblijvendAdvies from "./pages/VrijblijvendAdvies";
import Assortiment from "./pages/Assortiment";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AlgemeneVoorwaarden from "./pages/AlgemeneVoorwaarden";

import PlantPages from "./pages/PlantPages";

import Product from "./pages/shop/Product";
import Category from "./pages/shop/Category";
import Cart from "./pages/shop/order/Cart";
import DeliveryInfo from "./pages/shop/order/DeliveryInfo";
import PaymentMethodSelection from "./pages/shop/order/PaymentMethodSelection";
import OrderOverview from "./pages/shop/order/OrderOverview";
import OrderStatus from "./pages/shop/order/OrderStatus";

function PlantenPlazaRouter() {
    useTracking(["default"]);
    return (
        <React.Fragment>
            <ScrollToTop/>
            <div className="site-content">
                <Navbar/>
                <Switch>
                    { /* ShopCrate pages */ }
                    <Route path={[
                        "/product/:productId",
                        "/product/:productId/:productUrl"
                    ]} exact component={ Product }/>
                    <Route path={[
                        "/category/:categoryId",
                        "/category/:categoryId/:categoryUrl"
                    ]} exact component={ Category }/>
                    <Route path="/cart" exact component={ Cart }/>
                    <Route path="/order/data" exact component={ DeliveryInfo }/>
                    <Route path="/order/paymentmethod" exact component={ PaymentMethodSelection }/>
                    <Route path="/order/overview" exact component={ OrderOverview }/>
                    <Route path="/order/:orderToken" component={ OrderStatus }/>

                    { /* Product Content pages */ }
                    <Route path="/plant/:plantCodeName" component={ PlantPages }/>

                    { /* Site pages */ }
                    <Route path="/over-ons" component={ AboutUs }/>
                    <Route path="/referenties" component={ References }/>
                    <Route path="/top-5-kantoorplanten" component={ Top5 }/>
                    <Route path="/contact" component={ Contact }/>
                    <Route path="/vrijblijvend-advies" component={ VrijblijvendAdvies }/>
                    <Route path="/assortiment" component={ Assortiment }/>
                    <Route path="/privacyverklaring" component={ PrivacyPolicy }/>
                    <Route path="/algemene-voorwaarden" component={ AlgemeneVoorwaarden }/>
                    <Route path="/" component={ Home } exact/>

                    <Route path="/" component={ Error404 }/>
                </Switch>
            </div>
            <Footer/>
            <FloatingWhatsApp/>
        </React.Fragment>
    )
}

export default React.memo(PlantenPlazaRouter);
