import React from "react";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";
import {
    Link
} from "react-router-dom";

import image from "../../img/about-us/sustainable.jpg";
import VizSensor from "react-visibility-sensor";
import { Fade } from "react-reveal";

class AboutUsSustainable extends React.Component {
    state = {
        show: false
    }

    render() {
        return (
            <Jumbotron fluid
                       className="mb-0"
                       style={ {
                           backgroundColor: "white",
                           paddingTop: "7rem",
                           paddingBottom: "7rem",
                           overflow: "hidden"
                       } }>
                <Container>
                    <VizSensor
                        partialVisibility
                        minTopValue={ 200 }
                        onChange={ (isVisible) => {
                            this.setState({ show: isVisible ? true : this.state.show });
                        } }
                    >
                        <Row>
                            <Col md={ 7 }>
                                <Fade left when={ this.state.show }>
                                    <div className="pl-md-5 pr-md-5 pb-5 pb-md-0">
                                        <img src={ image } className="img-fluid rounded" alt="Room"/>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md={ 5 }>
                                <Fade left when={ this.state.show }>
                                    <h1 className="h1-big text-secondary">
                                        <b>de wereld groener & duurzamer maken</b>
                                    </h1>
                                </Fade>
                                <Fade up when={ this.state.show }>
                                    <p className="mt-4">
                                        Vanuit een gedeelde passie en jarenlange ervaring in de groene sector zijn wij
                                        PlantenPlaza gestart. Waar de één jarenlang
                                        ervaring heeft opgedaan op teelttechnisch gebied bij een gerenommeerd bedrijf in
                                        tropische en subtropische planten, heeft de ander jarenlange ervaring in diverse
                                        commerciële functies bij een toonaangevende kamerplantenexporteur. Ons doel is
                                        om de wereld op een duurzame wijze steeds iets groener te maken!
                                    </p>
                                    <Link to="/assortiment" className="btn btn-primary mt-3">Bekijk onze brochure</Link>
                                </Fade>
                            </Col>
                        </Row>
                    </VizSensor>
                </Container>
            </Jumbotron>
        );
    }
}

export default AboutUsSustainable;
