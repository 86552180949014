import React from "react";
import {
    Helmet
} from "react-helmet";
import {
    Col,
    Container, Jumbotron, Row
} from "react-bootstrap";
import {
    Fade
} from "react-reveal";
import ContactJumbotron from "../components/contact/ContactJumbotron";
import ContactForm from "../components/contact/ContactForm";

import backgroundImage from "./../img/contact-background.jpg";

class Contact extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Contact - PlantenPlaza</title>
                </Helmet>
                <ContactJumbotron/>

                <Jumbotron fluid
                           className="mb-0"
                           style={ {
                               backgroundColor: "white"
                           } }>
                    <Container className="mt-5 mb-5">
                        <Row>
                            <Col md="9">
                                <Fade up>
                                    <p>
                                        Op zoek naar een partner in interieurbeplanting? We are your men! We voorzien je graag
                                        in een vrijblijvend advies voor jouw ruimte en indien gewenst voeren we deze volledig
                                        naar wens uit. Wij zijn op elk moment van de dag zowel telefonisch als per mail
                                        bereikbaar. Ook kun je het formulier hieronder invullen en dan komen we zo snel mogelijk
                                        bij je terug.
                                    </p>
                                </Fade>
                            </Col>
                            <Col className="d-none d-md-block">
                                <Fade right>
                                    <h1 className="ml-3 mt-3 mt-md-0 mb-5 mb-md-0"
                                        style={ { fontSize: "2.5rem", fontWeight: 900, color: "#808B75" } }>
                                        plants are
                                        <br/>
                                        friends
                                    </h1>
                                </Fade>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>

                <Jumbotron fluid
                           className="mb-0"
                           style={ {
                               backgroundColor: "#808B75",
                               paddingTop: "5vh",
                               paddingBottom: "5vh",
                               overflow: "hidden",
                               backgroundImage: "url(" + backgroundImage + ")",
                               backgroundSize: "cover",
                               backgroundPosition: "center"
                           } }>
                    <Container className="mt-5 mb-5">
                        <Row>
                            <Col md={ 6 } lg={ 5 }>
                                <Fade left>
                                    <h1 style={ { color: "#414C36" } }>
                                        WIJ VINDEN HET LEUK
                                        <br/>
                                        OM VAN JE TE HOREN
                                    </h1>
                                </Fade>
                                <Fade up>
                                    <ul className="mt-5 mb-5 mb-md-0 ml-2 fa-ul" style={ {
                                        paddingLeft: "24px",
                                        marginLeft: 0,
                                        fontSize: "1.2rem",
                                        color: "#414C36"
                                    } }>
                                        <li className="mt-3">
                                            <span className="fa-li"><i className="fas fa-map-marker-alt"/></span>
                                            Kwintsheul
                                        </li>
                                        <li className="mt-3">
                                            <span className="fa-li"><i className="fas fa-envelope"/></span>
                                            plantenplaza@outlook.com
                                        </li>
                                        <li className="mt-3">
                                            <span className="fa-li"><i className="fas fa-phone"/></span>
                                            06 47 90 72 67
                                        </li>
                                    </ul>
                                </Fade>
                            </Col>
                            <Col md={ 6 } lg={ 7 }>
                                <Fade up>
                                    <ContactForm/>
                                </Fade>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>

            </React.Fragment>
        );
    }
}

export default Contact;
