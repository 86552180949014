import React from "react";
import Helmet from "../../components/Helmet";
import Error404 from "../404";
import CategoryPage from "../../components/shop/CategoryPage";

function Category() {
    return (
        <CategoryPage
            error404Page={ <Error404/> }
            helmet={ (categoryContext) => (
                <Helmet title={ categoryContext.category ? categoryContext.category.name : null }/>
            )}
        />
    );
}

export default React.memo(Category);
