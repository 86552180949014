import React from "react";
import {
    BrowserRouter as Router
} from "react-router-dom";
import {
    ShopCrateManager
} from "@shopcrate/shopcrate-framework";

import PlantenPlazaRouter from "./PlantenPlazaRouter";

import "./scss/style.scss";

function App() {
    return (
        <ShopCrateManager>
            <Router>
                <PlantenPlazaRouter/>
            </Router>
        </ShopCrateManager>
    );
}

export default App;
