import React, {
    useContext,
    useRef,
    useState
} from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    Container,
    Nav,
    Navbar as RBNavbar,
    NavDropdown
} from "react-bootstrap";
import {
    CartContext,
    ShopContext
} from "@shopcrate/shopcrate-framework";

import useOutsideAlerter from "../hooks/OutsideAlerter";
import categoryId from "./variables/categoryId";

import logo from "./../img/logo.svg";

function NavbarLink({to, title, exact = false}) {
    let match = useRouteMatch({
        path: to,
        exact: exact
    });
    let className = "nav-item ml-2 d-flex align-items-center " + (match ? "active" : "");
    return (
        <li className={className} data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={to} className="nav-link">
                { typeof(title) === "string" ? title.toUpperCase() : title }
            </Link>
        </li>
    );
}

function NavbarButton({to, title}) {
    return (
        <li data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={to} className="btn btn-primary ml-md-2 my-2 my-md-1" style={{ fontSize: ".8rem"}}>
                <b>{title.toUpperCase()}</b>
            </Link>
        </li>
    );
}

function DropdownLink({to, title, exact = false, onClick = undefined}) {
    let match = useRouteMatch({
        path: to,
        exact: exact
    });
    let className = "dropdown-item " + (match ? "active" : "");
    return (
        <Link to={ to } className={ className } onClick={ onClick }>{ title }</Link>
    );
}

function Navbar() {
    const shopContext = useContext(ShopContext);
    const cartContext = useContext(CartContext);

    let [showCollapsed, setShowCollapsed] = useState(false);
    let [showFamilyDropdown, setShowFamilyDropdown] = useState(false);

    const onNavigate = () => {
        setShowCollapsed(false);
        setShowFamilyDropdown(false);
    }

    let toggleFamilyDropdown = () => setShowFamilyDropdown(!showFamilyDropdown);

    let matchFamily = useRouteMatch({
        path: "/plant",
        exact: false
    });

    const familyDropdownRef = useRef(null);
    useOutsideAlerter(familyDropdownRef, () => setShowFamilyDropdown(false));

    return (
        <React.Fragment>
            <RBNavbar bg="light" expand="lg" className="fixed-top navbar-only" expanded={ showCollapsed }>
                <Container>
                    <a className="navbar-brand" href="/">
                        <img src={ logo } id="navbar-logo" height="45px" alt="PlantenPlaza"/>
                    </a>
                    <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ () => setShowCollapsed(!showCollapsed) } />
                    <RBNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"/>
                        <Nav>
                            <NavbarLink to="/over-ons" title="Interieur beplanting"/>
                            <NavbarLink to="/referenties" title="Referenties"/>
                            <NavbarLink to={ "/category/" + categoryId } title={
                                shopContext.shop && shopContext.shop.orderingEnabled ? (
                                    "Webshop"
                                ) : (
                                    "Assortiment"
                                )
                            }/>
                            <NavDropdown
                                ref={ familyDropdownRef }
                                id="cart-dropdown"
                                show={ showFamilyDropdown }
                                onClick={ toggleFamilyDropdown }
                                title="THE FAMILY"
                                alignRight
                                className={ "ml-2 d-lg-flex align-self-lg-center" + (matchFamily !== null ? " active" : "") }
                            >
                                <DropdownLink to="/plant/alocasia-portadora-patrick" title="Alocasia Portadora Patrick" onClick={ onNavigate }/>
                                <DropdownLink to="/plant/aralia-polyscias-fabian" title="Aralia Polyscias Fabian" onClick={ onNavigate }/>
                                <DropdownLink to="/plant/areca-dypsis-arjan" title="Areca Dypsis Arjan" onClick={ onNavigate }/>
                                <DropdownLink to="/plant/dracaena-reflexa-danny" title="Dracaena Reflexa Danny" onClick={ onNavigate }/>
                                <DropdownLink to="/plant/ficus-lyrata" title="Ficus Lyrata Lynn & Lisa" onClick={ onNavigate }/>
                                <DropdownLink to="/plant/homelomena-maggie" title="Homelomena Maggie" onClick={ onNavigate }/>
                                <DropdownLink to="/plant/monstera" title="Monstera" onClick={ onNavigate }/>
                                <DropdownLink to="/plant/phoenix-roebellini-ruby" title="Phoenix Roebellini Ruby" onClick={ onNavigate }/>
                                <DropdownLink to="/plant/strelitzia-nicolai-sterre" title="Strelitzia Nicolai Sterre" onClick={ onNavigate }/>
                                <DropdownLink to="/plant/yucca-joris" title="Yucca Joris" onClick={ onNavigate }/>
                            </NavDropdown>
                            <NavbarLink to="/contact" title="Contact"/>
                            <NavbarButton to="/vrijblijvend-advies" title="Vrijblijvend advies"/>
                            { shopContext.shop && shopContext.shop.orderingEnabled && (
                                <NavbarLink to="/cart" title={
                                    <div style={{ whiteSpace: "nowrap" }}>
                                        <i className="fas fa-shopping-cart"/>
                                        <span className="badge badge-pill badge-primary ml-2">
                                            { cartContext.cart === null ? 0 : cartContext.cart.length }
                                        </span>
                                    </div>
                                }/>
                            )}
                        </Nav>
                    </RBNavbar.Collapse>
                </Container>
            </RBNavbar>
            <div className="navbar-page-spacer"/>
        </React.Fragment>
    );
}

export default Navbar;
