import React from "react";
import {
    Link
} from "react-router-dom";

function CallToAction() {
    return (
        <div className="call-to-action" style={{ marginTop: "100px", marginBottom: "100px" }}>
            <h1>Neem vandaag nog vrijblijvend contact met ons op</h1>
            <Link to="/contact" className="call-to-action-button">Neem contact op</Link>
        </div>
    )
}

export default CallToAction;
