import React from "react";
import {
    Container,
    Jumbotron
} from "react-bootstrap";
import {
    Fade
} from "react-reveal";

import background from "../../img/jumbotron-background.jpg";

function AboutUsJumbotron() {
    return (
        <Jumbotron fluid
                   className="jumbotron-hero mb-0"
                   style={ {
                       backgroundImage: `url(${ background })`,
                       overflow: "hidden"
                   } }>
            <div className="hero-mobile-fader">
                <Container className="text-center">
                    <Fade right duration={2000}>
                        <h1>
                            WHEN LIFE GIVES
                            <br/>
                            YOU <span className="text-primary">PLANTS...</span>
                        </h1>
                    </Fade>
                </Container>
            </div>
        </Jumbotron>
    )
}

export default AboutUsJumbotron;
