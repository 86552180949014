import React from "react";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";

function Footer() {
    const socialButtonStyle = {
        fontSize: "1.3rem",
        color: "#404B35",
        paddingTop: 0,
        paddingBottom: 0,
    }
    const pageLinkStyle = {
        color: "white",
        paddingLeft: 0,
        paddingRight: 0
    }
    return (
        <React.Fragment>
            <Jumbotron fluid className="mb-0 text-white" style={{ backgroundColor: "#404B35" }}>
                <Container>
                    <Row>
                        <Col md="4" lg="3" className="mb-3">
                            <h3 className="amaranth">Contact</h3>
                            <ul className="mt-2 fa-ul" style={ {
                                paddingLeft: "24px",
                                marginLeft: 0
                            } }>
                                <li className="mt-3">
                                    <span className="fa-li text-primary"><i className="fas fa-map-marker-alt"/></span>
                                    Kwintsheul
                                </li>
                                <li className="mt-3">
                                    <span className="fa-li text-primary"><i className="fas fa-envelope"/></span>
                                    <a href="mailto:plantenplaza@outlook.com" style={pageLinkStyle}>plantenplaza@outlook.com</a>
                                </li>
                                <li className="mt-3">
                                    <span className="fa-li text-primary"><i className="fas fa-phone"/></span>
                                    <a href="dial:+31647907267" style={pageLinkStyle}>06 47 90 72 67</a>
                                </li>
                            </ul>
                        </Col>
                        <Col md="3" className="mb-3">
                            <h3 className="amaranth">Pagina's</h3>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link active" style={pageLinkStyle}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/over-ons" className="nav-link active" style={pageLinkStyle}>Over ons</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link active" style={pageLinkStyle}>Contact</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/privacyverklaring" className="nav-link active" style={pageLinkStyle}>Privacyverklaring</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/algemene-voorwaarden" className="nav-link active" style={pageLinkStyle}>Algemene Voorwaarden</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col className="d-block d-md-none text-center">
                            <a
                                href="https://web.whatsapp.com/send?phone=31647907267"
                                className="btn btn-primary text-white"
                                target="_blank"
                                rel="noreferrer noopener"
                                style={{
                                    backgroundColor: "#25D366",
                                    borderColor: "#25D366"
                                }}
                            >
                                <i className="fab fa-whatsapp mr-1"/> Kunnen we je helpen?
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Jumbotron fluid className="mb-0 pt-3 pb-3" style={{ backgroundColor: "#AABF55" }}>
                <Container>
                    <Row>
                        <Col md={{ span: 6, order: 2 }} className="float-right mb-3 mb-md-0 d-flex justify-content-center justify-content-md-end">
                            <div className="float-md-right">
                                <ul className="nav">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="https://facebook.com/plantenplaza-2247618692154990" target="_blank" rel="noopener noreferrer" style={socialButtonStyle}>
                                            <i className="fab fa-facebook-square"/>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" href="https://instagram.com/plantenplaza" target="_blank" rel="noopener noreferrer" style={socialButtonStyle}>
                                            <i className="fab fa-instagram"/>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" href="https://linkedin.com/company/plantenplaza" target="_blank" rel="noopener noreferrer" style={socialButtonStyle}>
                                            <i className="fab fa-linkedin"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="6">
                            <p className="mb-0 mt-1 text-center text-md-left" style={{ color: "#404B35" }}>
                                &copy; 2019 - { moment().year() } PlantenPlaza.
                                <span className="d-md-none"><br/></span>{" "}
                                A website by <a href="https://srproductions.nl" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>SR Productions</a>.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        </React.Fragment>
    );
}

export default Footer;
