import React from "react";
import {
    OrderStatusPageContent
} from "@shopcrate/shopcrate-framework";

import Helmet from "../../../components/Helmet";

import bannerImage from "../../../img/top_10_kantoorplanten.jpg";

function OrderStatus() {
    return (
        <React.Fragment>
            <Helmet title="Betaalmethode"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm" style={ { backgroundImage: "url(" + bannerImage + ")" } }>
                <div className="container">
                    <h1 className="text-white">Bestelling</h1>
                </div>
            </div>
            <div className="container mb-5">
                <OrderStatusPageContent/>
            </div>
        </React.Fragment>
    );
}

export default React.memo(OrderStatus);
