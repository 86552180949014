import React from "react";
import ContentPage from "../../components/product-content-page/ContentPage";

import topBannerImage from "../../img/product-content-pages/strelitzia-nicolai-sterre/topBanner.jpg";
import kenmerkenImage from "../../img/product-content-pages/strelitzia-nicolai-sterre/kenmerken.jpg";

function StrelitziaNicolaiSterre() {
    return (
        <ContentPage
            productIds={[28]}
            topBannerImage={ topBannerImage }
            title="Strelitzia Nicolai Sterre"
            shortName="Sterre"
            topDescription="De Strelitzia Nicolai is een exclusieve bloeiende kamerplant en wordt ook wel Paradijsvogelplant genoemd. Deze naam heeft hij te danken aan de vorm en kleur van de bloemen."
            kenmerken={[
                "Langwerpige, veerachtige stelen en bladeren",
                "Intens mooie groene kleur",
                "Moderne eyecatcher die elke hoek opvult"
            ]}
            kenmerkenImage={ kenmerkenImage }
            specifications={{
                "geschikt voor": "thuis & kantoor",
                "soort plant": "Strelitzia Nicolai",
                "herkomst": "Zuid-Afrika",
                "houdt van": "licht, warmte & water",
                "maat & hoogte": "pot Ø 34 - 160 cm"
            }}
            verzorging={{
                "water geven": "Tijdens zomer heeft de Strelitzia graag veel water, zo’n 1 keer per week. Als de grond na een weekje weer droog is, is dit een teken dat hij genoeg krijgt. In de winter mag de grond wat droger raken. Je geeft hem nog steeds 1 keer per week water, maar minder dan in de zomer.",
                "sproeien": "Regelmatig sproeien houdt de plant vrij van stof, wat ervoor zorgt dat hij zo veel mogelijk licht kan opvangen.",
                "voeden": "Voed de plant alleen in de lente en zomer, eens per 6 tot 8 weken moet voldoende zijn. Te veel voeding is nooit goed, dus bij twijfel geef je liever iets minder.",
                "verpotten": "Verpot de Strelitzia direct na aanschaf in een 20% grotere pot zodat hij goed verder kan groeien. We raden aan dit iedere 2 jaar te doen.",
                "standplaats": "De Strelitzia staat het liefst op een lekker lichte plek, bij voorkeur voor het raam. Te weinig licht zorgt voor een lelijk blad en weinig tot geen groei."
            }}
            bottomBannerImage={ topBannerImage }
        />
    );
}

export default React.memo(StrelitziaNicolaiSterre);
