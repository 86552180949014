import React from "react";
import ContentPage from "../../components/product-content-page/ContentPage";

import topBannerImage from "../../img/product-content-pages/phoenix-roebellini-ruby/topBanner.jpg";
import kenmerkenImage from "../../img/product-content-pages/phoenix-roebellini-ruby/kenmerken.jpg";

function PhoenixRoebelliniRuby() {
    return (
        <ContentPage
            productIds={[26]}
            topBannerImage={ topBannerImage }
            title="Phoenix Roebellini Ruby"
            shortName="Ruby"
            topDescription="De Phoenix Roebellini staat ook wel bekend als Dwergdadelpalm en staat graag op een zonnige plek te stralen. "
            kenmerken={[
                "Tropische sfeermaker",
                "Stekels aan het begin van de bladeren, pas op met kinderen/huisdieren",
                "Mooie exotische palm stam"
            ]}
            kenmerkenImage={ kenmerkenImage }
            specifications={{
                "geschikt voor": "thuis & kantoor",
                "soort plant": "Phoenix Roebellini",
                "herkomst": "Zuid-Oost Azië",
                "houdt van": "licht, warmte & water",
                "maat & hoogte": "pot Ø 30 - 150 cm"
            }}
            verzorging={{
                "water geven": "Phoenix Roebellini palmen verbruiken veel water. De grond mag daarom nooit droog zijn! Geef hem 2 tot 3 keer per week water en in de zomer het liefst dagelijks, afhankelijk van de warmte. De hoeveelheid water die je per keer geeft hangt af van de grootte van de pot. Bij een grote pot heeft de plant minder vaak water nodig.",
                "sproeien": "De Phoenix Roebellini verliest veel vocht doordat het snel verdampt. Regelmatig sproeien zal dit tegengegaan worden en blijft de plant mooi en gezond.",
                "voeden": "In de lente en zomer heeft de plant wekelijks voeding nodig. In de herfst en winter kan hij prima zonder voeding. Je gebruikt voor het voeden de hiervoor geschikte palmenvoeding.",
                "verpotten": "Je verpot de Phoenix Roebellini het beste meteen na aanschaf in een 25% grotere pot zodat deze veel water kan absorberen. Vervolgens verpot je hem eens per 2 jaar zodat hij weer goed verder kan groeien, bij voorkeur in de lente. De plant heeft scherpe naalden, dus pas bij het verpotten op dat je je handen beschermt.",
                "standplaats": "Plaats de palm 1 tot 2 meter van het raam, zodat deze zo’n 5 uur direct zonlicht krijgt. Op een te donkere plek zal hij geen nieuw blad aanmaken."
            }}
            bottomBannerImage={ topBannerImage }
        />
    );
}

export default React.memo(PhoenixRoebelliniRuby);
