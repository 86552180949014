import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
    ProductPageContext
} from "@shopcrate/shopcrate-framework";

function ProductContentPageButton() {
    const productContext = useContext(ProductPageContext);
    if(!productContext.product) {
        return (
            <Skeleton height={ 42 } width={ 100 }/>
        )
    }
    if(!productContext.product.parameters.CONTENT_PAGE) {
        return null;
    }
    return (
        <Link to={ "/plant/" + productContext.product.parameters.CONTENT_PAGE } className="btn btn-outline-primary mt-3">
            Meer info
        </Link>
    )
}

export default React.memo(ProductContentPageButton);
