import React, {
    useContext
} from "react";
import Skeleton from "react-loading-skeleton";
import {
    ProductPageContext
} from "@shopcrate/shopcrate-framework";

function ProductTitle() {
    const productContext = useContext(ProductPageContext);
    return (
        <h1 className="mb-0 h2">
            { productContext.product ? productContext.product.name : (
                <Skeleton/>
            )}
        </h1>
    )
}

export default React.memo(ProductTitle);
