import React from "react";
import ContentPage from "../../components/product-content-page/ContentPage";

import topBannerImage from "../../img/product-content-pages/monstera/topBanner.jpg";
import kenmerkenImage from "../../img/product-content-pages/monstera/kenmerken.jpg";

function Monstera() {
    return (
        <ContentPage
            productIds={[25]}
            productNames={["Mo"]}
            topBannerImage={ topBannerImage }
            title="Monstera"
            shortName="Mo"
            topDescription="De Monstera is een van de meest populaire kamerplanten voor in huis! Hij wordt ook wel de Gatenplant genoemd doordat de bladeren niet helemaal heel zijn. Naast zijn uiterlijk maakt ook de makkelijke verzorging dit een geliefde plant. We hebben 2 mooie varianten van de Monstera!"
            kenmerken={[
                "Mooie, grote, groene gatenbladeren",
                "Makkelijk te verzorgen",
                "Super trendy",
                "Kan goed in het donker staan"
            ]}
            kenmerkenImage={ kenmerkenImage }
            specifications={{
                "geschikt voor": "thuis & kantoor",
                "soort plant": "monstera",
                "herkomst": "Midden- en Zuid-Amerika",
                "houdt van": "schaduw, warmte & water",
                "maat & hoogte": "pot Ø 27 - 90/130 cm"
            }}
            verzorging={{
                "water geven": "De monstera gedijt goed bij weinig water, maar heeft wel graag een licht vochtige grond. Te veel water is een no go bij deze plant, dus check altijd even of hij geen natte voeten heeft.",
                "sproeien": "De monstera wordt graag vaak besproeid. Dit houdt de plant vitaal en zorgt dat hij stofvrij blijf. Zo komt hij het beste uit de verf!",
                "voeden": "Na het aanschaffen van de monstera kan hij 2 maanden prima zonder voeding. Daarna geef je hem in de lente en zomer het beste 2 keer per maand voeding en in de winter 1 keer per maand.",
                "verpotten": "Verpot de monstera een keer per twee jaar, bij voorkeur in hete voorjaar zodat hij lekker kan groeien. Kies hierbij voor een pot van minimaal 20% groter.",
                "standplaats": "De monstera staat graag op een plek half in de schaduw of in het licht, maar niet in direct zonlicht. Een teken van te weinig licht is wanneer de plant geen nieuw blad aanmaakt of het blad klein of bleekjes is."
            }}
            bottomBannerImage={ topBannerImage }
        />
    );
}

export default React.memo(Monstera);
