import React from "react";
import axios from "axios";
import {
    stringify
} from "qs";
import {
    Alert,
    Col,
    Row
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from "react-spinner-material";

class ContactForm extends React.Component {
    state = {
        submitted: false,
        loading: false,
        error: null,

        name: "",
        phoneNumber: "",
        email: "",
        subject: "",
        message: "",
        acceptPrivacyPolicy: false
    }

    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();
    }

    onFormSubmit(event) {
        event.preventDefault();
        this.setState({ error: null });
        this.recaptchaRef.current.reset();
        this.recaptchaRef.current.execute();
    }

    onRecaptchaVerify(recaptchaData) {
        console.log(recaptchaData);
        if(recaptchaData !== null) {
            this.submitFormData(recaptchaData);
        }
    }

    getAxios() {
        return axios.create({
            baseURL: process.env.REACT_APP_API_URL ? "https://" + process.env.REACT_APP_API_URL + "/v1/" : "https://dev.api.plantenplaza.com/v1/",
            transformRequest: (data) => {
                data = stringify(data);
                return data;
            }
        });
    }

    submitFormData(recaptchaData) {
        const data = {
            recaptchaData: recaptchaData,
            name: this.state.name,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message,
            acceptPrivacyPolicy: this.state.acceptPrivacyPolicy
        }
        console.log(data);
        this.setState({ loading: true });
        this.getAxios().post("/submitContactForm", data)
            .then((response) => {
                console.log(response.data);
                if(response.data.valid) {
                    console.log("Form has successfully been submitted.");
                    this.setState({ submitted: true, loading: false });
                } else {
                    console.log("Form error occured: " + response.data.error);
                    this.setState({ error: response.data.error, loading: false });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
    }

    render() {
        return (
            <React.Fragment>
                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}

                { this.state.submitted ?  (
                    <div className="card mb-2 text-center" style={{ marginTop: "50px", marginBottom: "50px" }}>
                        <div className="card-body">
                            <h1>
                                Bedankt voor uw
                                <br/>
                                contact aanvraag!
                            </h1>
                            <p>Wij gaan zo spoedig mogelijk contact met u opnemen.</p>
                        </div>
                    </div>
                ) : (
                    <form id="contact-form" noValidate onSubmit={ this.onFormSubmit.bind(this) }>
                        <div className="card mb-2 bg-dark-green text-white" style={{ border: "none" }}>
                            <div className="card-body">
                                <Row noGutters>
                                    <Col sm={ 6 } md={ 12 } lg={ 6 }>
                                        <div className="form-group mr-sm-2 mr-md-0 mr-lg-2">
                                            <input type="text" className="form-control" id="name" name="name" placeholder="Naam" required
                                                   disabled={ this.state.loading }
                                                   value={ this.state.name }
                                                   onChange={ (event) => { this.setState({ name: event.target.value })}}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={ 6 } md={ 12 } lg={ 6 }>
                                        <div className="form-group ml-sm-2 ml-md-0 ml-lg-2">
                                            <input type="email" className="form-control" id="email" name="email" placeholder="Email" required
                                                   disabled={ this.state.loading }
                                                   value={ this.state.email }
                                                   onChange={ (event) => { this.setState({ email: event.target.value })}}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col sm={ 6 } md={ 12 } lg={ 6 }>
                                        <div className="form-group mr-sm-2 mr-md-0 mr-lg-2">
                                            <input type="text" className="form-control" id="phonenumber" name="phonenumber" placeholder="Telefoonnummer" required
                                                   disabled={ this.state.loading }
                                                   value={ this.state.phoneNumber }
                                                   onChange={ (event) => { this.setState({ phoneNumber: event.target.value })}}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={ 6 } md={ 12 } lg={ 6 }>
                                        <div className="form-group ml-sm-2 ml-md-0 ml-lg-2">
                                            <input type="text" className="form-control" id="subject" name="subject" placeholder="Onderwerp" required
                                                   disabled={ this.state.loading }
                                                   value={ this.state.subject }
                                                   onChange={ (event) => { this.setState({ subject: event.target.value })}}
                                            />
                                        </div>
                                    </Col>
                                </Row>



                                <div className="form-group">
                                    <textarea className="form-control" id="message" name="message" placeholder="Bericht" style={ { height: "100px" } }
                                              disabled={ this.state.loading }
                                              value={ this.state.message }
                                              onChange={ (event) => { this.setState({ message: event.target.value })}}
                                    />
                                </div>

                                <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" name="iaccept" id="iaccept" required
                                           disabled={ this.state.loading }
                                           checked={ this.state.acceptPrivacyPolicy }
                                           onChange={ (event) => { this.setState({ acceptPrivacyPolicy: event.target.checked })}}
                                    />
                                    <label className="custom-control-label" htmlFor="iaccept">
                                        Ik ga akkoord met
                                        de <a href="/privacyverklaring" target="_blank" rel="noreferrer noopener">privacyverklaring <small><i className="fas fa-external-link" aria-hidden="true"/></small></a>. <b className="text-danger">*</b>
                                    </label>
                                </div>

                                { this.state.loading ? (
                                    <div className="float-right">
                                        <Spinner radius={60} color="#414C36" stroke={4} visible={true} />
                                    </div>
                                ) : (
                                    <input type="submit" className="btn btn-primary btn-lg float-right" value="Versturen"/>
                                )}

                                <div>
                                    <br className="d-sm-none d-md-block d-lg-none"/>
                                    <br className="d-sm-none d-md-block d-lg-none"/>
                                    <br className="d-sm-none d-md-block d-lg-none"/>
                                    <small style={{ color: "#859971" }}>
                                        This site is protected by reCAPTCHA and the<br/>Google{" "}
                                        <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and{" "}
                                        <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer noopener">Terms of Service</a> apply.
                                    </small>
                                </div>

                                <ReCAPTCHA
                                    ref={ this.recaptchaRef }
                                    size="invisible"
                                    sitekey="6LcSGrAUAAAAACckXkodnKsc3sV-O9_SwEF96Qvq"
                                    onChange={ this.onRecaptchaVerify.bind(this) }
                                />
                            </div>
                        </div>

                    </form>
                ) }
            </React.Fragment>
        );
    }
}

export default ContactForm;
