import React from "react";
import {
    Helmet as ReactHelmet
} from "react-helmet";

function Helmet({ title = null, description = null }) {
    const formattedTitle = title === null ? "PlantenPlaza" : (title + " - PlantenPlaza");
    return (
        <ReactHelmet>
            <title>{ formattedTitle }</title>
            <meta name="title" content={ formattedTitle }/>
            <meta name="twitter:title" content={ formattedTitle }/>
            <meta property="og:title" content={ formattedTitle }/>

            <meta name="description" content={ description }/>
            <meta name="twitter:description" content={ description }/>
            <meta property="og:description" content={ description }/>
        </ReactHelmet>
    );
}

export default React.memo(Helmet);
