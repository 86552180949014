import React from "react";
import ContentPage from "../../components/product-content-page/ContentPage";

import topBannerImage from "../../img/product-content-pages/ficus-lyrata-lynn/topBanner.jpg";
import kenmerkenImage from "../../img/product-content-pages/ficus-lyrata-lynn/kenmerken.jpg";
import bottomBannerImage from "../../img/product-content-pages/ficus-lyrata-lynn/bottomBanner.jpg";

function FicusLyrata() {
    return (
        <ContentPage
            productIds={[29, 30]}
            productNames={["Lynn", "Lisa"]}
            topBannerImage={ topBannerImage }
            title="Ficus Lyrata"
            shortName="Lynn"
            topDescription="De Ficus Lyrata wordt ook wel Tabaksplant of Vioolbladplant genoemd vanwege de vorm van de grote bladeren. De bladeren zijn van onder smal een lopen breder uit. In de natuur kan de Ficus Lyrata wel 20 meter hoog worden en de bladeren een halve meter lang. Deze worden ook wel gedroogd om vervolgens sigaren van te maken, dit verklaart dan ook de naam Tabaksplant. Met deze plant heb je echt een moderne eyecatcher in huis! We hebben 2 prachtige varianten van de Ficus Lyrata. Welke gaat er bij jou thuis shinen?"
            kenmerken={[
                "Moderne eyecatcher",
                "Grote sterke bladeren",
                "Mooie dikke stam",
                "Eén van de sterkste Ficus soorten die weinig blad laat vallen"
            ]}
            kenmerkenImage={ kenmerkenImage }
            specifications={{
                "geschikt voor": "thuis & kantoor",
                "soort plant": "Ficus Lyrata",
                "herkomst": "Zuid-West Azië",
                "houdt van": "licht, warmte & water",
                "maat & hoogte": "pot Ø 34 - 150 cm"
            }}
            verzorging={{
                "water geven": "De Ficus Lyrata is het gelukkigst wanneer haar grond altijd licht vochtig is en de wortels niet onder water staan. Je kunt het beste steeds kleine beetjes water geven om wortelrot te voorkomen. In de winter kun je gerust minder water geven.",
                "sproeien": "Wanneer je regelmatig sproeit zal stof en spint ver van de Ficus vandaan blijven. Zo blijft zij het allermooist! In de zomer is 1 x per week voldoende en in de winter kun je dit het beste zo’n 2 x per week doen.",
                "voeden": "In de lente en zomer groeit de Ficus Lyrata, dan is kamerplantenvoeding ongeveer elke 6 tot 8 weken nodig. In de winter hoef je juist weer geen voeding te geven.",
                "verpotten": "Je kunt je Ficus het beste iedere 2 jaar verpotten en bij voorkeur in de lente. Zo kunnen de wortels goed herstellen! Kies een pot die zo’n 20% groter is dan de pot waarin je de plant ontvangt. Let op! Het sap van de Ficus Lyrata is licht giftig. Houd kinderen en dieren daarom uit de buurt en draag handschoenen wanneer je de plant verpot.",
                "standplaats": "De Ficus Lyrata houdt van zonlicht! Je maakt haar gelukkig wanneer ze minstens 5 uur zonlicht per dag krijgt. Plaats de plant een paar meter van een raam op het zuiden of vlak voor een raam op het noorden, oosten of westen."
            }}
            bottomBannerImage={ bottomBannerImage }
        />
    );
}

export default React.memo(FicusLyrata);
