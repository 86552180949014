import React from "react";
import {
    Helmet
} from "react-helmet";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";
import {
    Fade
} from "react-reveal";

import ContactForm from "../components/contact/ContactForm";
import backgroundImage from "../img/contact-background.jpg";

class VrijblijvendAdvies extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Vrijblijvend Advies - PlantenPlaza</title>
                </Helmet>
                <Jumbotron fluid
                           className="mb-0"
                           style={ {
                               backgroundColor: "#808B75",
                               paddingTop: "15vh",
                               paddingBottom: "15vh",
                               overflow: "hidden",
                               backgroundImage: "url(" + backgroundImage + ")",
                               backgroundSize: "cover",
                               backgroundPosition: "center"
                           } }>
                    <Container className="mt-mb-5 mb-mb-5">
                        <Row>
                            <Col md="6" xs="12" className="pr-5 mb-5">
                                <Fade left>
                                    <h1 className="allura allura-big" style={ { fontSize: "3rem" } }>
                                        hi siri, water my plants
                                    </h1>
                                </Fade>
                                <Fade left>
                                    <h1 style={ { color: "#414C36", fontWeight: 600 } }>
                                        PLANT-ADVIES NODIG?
                                    </h1>
                                </Fade>
                                <Fade up>
                                    <p className="mt-5">
                                        Zit je met je handen in het haar omdat je plant er ongelukkig uit ziet? Weet je niet
                                        welke planten geschikt zijn voor jouw huis of kantoor? Of welke planten je kunt
                                        plaatsen
                                        bij kinderen en huisdieren? Wat je vraag ook is, laat ons je adviseren!
                                    </p>
                                </Fade>
                            </Col>
                            <Col md="6" xs="12">
                                <Fade up>
                                    <ContactForm/>
                                </Fade>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
            </React.Fragment>
        )
    }
}

export default VrijblijvendAdvies;
