import React from "react";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";
import {
    Fade
} from "react-reveal";
import {
    Link
} from "react-router-dom";

import background from "../../img/about-us/call-to-action.jpg";

function AboutUsCallToAction() {
    return (
        <Jumbotron fluid
                   className="jumbotron-hero mb-0"
                   style={ {
                       backgroundColor: "white",
                       background: `url(${ background })`,
                       backgroundPosition: "center",
                       backgroundSize: "cover",
                       display: "flex",
                       alignItems: "center",
                       overflow: "hidden"
                   } }>
            <div className="hero-mobile-fader">
                <Container>
                    <Row>
                        <Col/>
                        <Col lg={ 6 } md={ 12 }>
                            <Fade right duration={2000}>
                                <h1 className="h1-big">
                                    "EEN BLIJE KLANT
                                    <br/>
                                    IS EEN BLIJE PLANT"
                                </h1>
                            </Fade>
                            <Fade up duration={2000}>
                                <Link to="/referenties" className="btn btn-primary btn-lg text-white mt-3">
                                    Bekijk onze referenties
                                </Link>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Jumbotron>
    )
}

export default AboutUsCallToAction;
