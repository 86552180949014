import React from "react";
import ContentPage from "../../components/product-content-page/ContentPage";

import topBannerImage from "../../img/product-content-pages/yucca-joris/topBanner.jpg";
import kenmerkenImage from "../../img/product-content-pages/yucca-joris/kenmerken.jpg";

function YuccaJoris() {
    return (
        <ContentPage
            productIds={[32]}
            topBannerImage={ topBannerImage }
            title="Yucca Joris"
            shortName="Joris"
            topDescription="De Yucca wordt vaak ook wel Palmlelie genoemd. Doordat Joris weinig water nodig heeft is hij erg gemakkelijk gezelschap! Wel wordt hij (letterlijk) graag in het zonnetje gezet. Mede door de dikke stam heb je met mij in huis ook dikke 100% kans op een enorme eye catcher."
            kenmerken={[
                "Makkelijk in onderhoud",
                "Heeft weinig water nodig",
                "Staat graag in het licht",
                "Geschikt voor thuis & op kantoor",
                "Min. temperatuur's nachts 10 graden, overdag 18 graden",
                "Kan giftig zijn, dus kijk uit met kinderen en huisdieren"
            ]}
            kenmerkenImage={ kenmerkenImage }
            specifications={{
                "geschikt voor": "thuis & kantoor",
                "soort plant": "Yucca",
                "herkomst": "Midden-Amerika",
                "houdt van": "licht",
                "maat & hoogte": "pot 34 - 130 cm"
            }}
            verzorging={{
                "water geven": "Ik ben niet zo dol op water en kan best 4 tot 6 weken zonder. Op warmere dagen heb ik echter wel iets vaker water nodig, maar nog steeds niet enorm veel. Wanneer je mij te veel water geeft, kunnen mijn wortels gaan rotten of wordt mijn stam zacht. Je hebt mij genoeg water gegeven wanneer de grond het vocht kan opnemen.",
                "sproeien": "Je hoeft mij niet per se te sproeien, maar als ik wat stoffig ben kan een sproeibeurt wel helpen mij weer te laten shinen.",
                "voeden": "Je hoeft mij alleen in de lente en zomer te voeden en liever niet in de herfst en winter. Mij voeden doe je met universele plantenvoeding, maar niet te veel! Dit kan namelijk leiden tot een zure grond waardoor mijn wortels verbranden.",
                "verpotten": "Je hoeft mij ongeveer 1 keer in de 3 jaar te verpotten. Doe dit bij voorkeur in de lente, zodat mijn wortels goed kunnen herstellen. Kies bij het verpotten voor een grotere pot en gebruik hier normale potgrond in. Zo kan ik weer goed verder groeien!",
                "standplaats": "Ik kan prima lekker in het licht staan, maar plaats mij liever niet meteen in direct zonlicht! Na verloop van tijd kun je mij steeds dichter bij het raam plaatsen. Eenmaal gewend krijg ik sta ik graag zo’n 5 uur per dag in het zonnetje."
            }}
            bottomBannerImage={ topBannerImage }
        />
    );
}

export default React.memo(YuccaJoris);
