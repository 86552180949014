import React from "react";
import {
    Helmet
} from "react-helmet";

import HomeJumbotron from "../components/home/HomeJumbotron";
import HomeCollection from "../components/home/HomeCollection";
// import HomeBelieveJumbotron from "../components/home/HomeBelieveJumbotron";
// import HomeCallToAction from "../components/home/HomeCallToAction";

class Home extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>PlantenPlaza</title>
                </Helmet>
                <HomeJumbotron/>
                <HomeCollection/>
                {/*<HomeBelieveJumbotron/>*/}
                {/*<HomeCallToAction/>*/}
            </React.Fragment>
        );
    }
}

export default Home;
