import React from "react";
import {
    Helmet
} from "react-helmet";
import { Container, Jumbotron } from "react-bootstrap";
import { Fade } from "react-reveal";

import ReferencesJumbotron from "../components/references/ReferencesJumbotron";
import Reference from "../components/references/Reference";

import vanVlietMotorsportLogo from "../img/references/logos/vanvlietmotorsport-logo.png";
import linuxLogo from "../img/references/logos/linux-logo.png";
import dgmrLogo from "../img/references/logos/dgmr-logo.svg";
import turboTheorieLogo from "../img/references/logos/turbotheorie.png";
import mkContractingLogo from "../img/references/logos/mk-contracting-logo.png";
import ReferenceDeck from "../components/references/ReferenceDeck";

class References extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Referenties - PlantenPlaza</title>
                </Helmet>
                <ReferencesJumbotron/>

                <Jumbotron fluid
                           className="mb-0"
                           style={ {
                               backgroundColor: "white",
                               paddingBottom: 0
                           } }
                >
                    <Container className="pl-md-5 pr-md-5">
                        <Fade left>
                            <p className="text-center mb-0 ml-md-5 mr-md-5">
                                Wij hebben inmiddels al verschillende klanten mogen verblijden met <b>mooie planten op kantoor</b>. Bij
                                elke klant zijn we vooraf langsgegaan om een <b>passend advies</b> te kunnen geven en te voorzien in
                                een groenplan op maat. We gaan bij elke klant geregeld langs om het onderhoud te verzorgen.
                                Wij <b>onderhouden</b> niet alleen graag planten, maar ook de relatie met onze klanten!
                            </p>
                        </Fade>
                    </Container>
                </Jumbotron>

                <Jumbotron fluid
                           className="mb-0 pb-2"
                           style={ {
                               backgroundColor: "white"
                           } }
                >
                    <Container>
                        <ReferenceDeck>
                            <Reference name="Linux Contracting & Detachering" logo={ linuxLogo }>
                                Voor ons net geopende kantoor was ik op zoek naar een goede partner in interieurbeplanting
                                en via de website kwam ik uit bij de mannen van PlantenPlaza. Zij zijn toen langsgekomen om
                                de locatie te bekijken op zaken als lichtinval en temperatuur en hebben vervolgens
                                geadviseerd welke planten er in het kantoor geplaatst konden worden. Deze zijn netjes binnen
                                een week op het afgesproken tijdstip geleverd en tot op heden is er naar alle tevredenheid
                                nazorg gegeven. Nog steeds elke dag erg blij met de geleverde planten!
                            </Reference>
                        </ReferenceDeck>

                        <ReferenceDeck>
                            <Reference name="Van Vliet Motorsport" logo={ vanVlietMotorsportLogo }>
                                Wij hebben zelf geen verstand van planten en wilde de zaak met planten aankleden.
                                Plantenplaza kon ons na een bezoek op de zaak heel goed informeren. Nu een tijdje later
                                staat alles er nog steeds top bij en zijn ze zelfs meerdere malen langs gekomen om te kijken
                                of alles er nog goed bij staat. Al met al helemaal goud!
                            </Reference>
                            <Reference name="TurboTheorie.nl" logo={ turboTheorieLogo }>
                                Via een bekende terecht gekomen bij deze heren en wat een beleving. Ze komen langs,
                                kijken en denken met je mee, adviseren en gaan tot het uiterst voor je. Echt alles uit
                                de kast om het beeld te creëren wat je wilt hebben. Wij zijn superblij met ze en super
                                blij met de planten.
                            </Reference>
                        </ReferenceDeck>

                        <ReferenceDeck>
                            <Reference name="DGMR Ingenieurs & Adviseurs" logo={ dgmrLogo }>
                                Wij zijn erg tevreden met de planten en de deal die PlantenPlaza ons aanbood. I.v.m. onze
                                gezonde werkomgeving (WELL) wilden we meer groen op kantoor. Samen hebben we gekeken naar de
                                mogelijkheden qua kantoorplanten en ons budget. De levering en plaatsing was vlot en een
                                goed contact met de heren van PlantenPlaza, met een heel mooi groen resultaat.
                            </Reference>
                            <Reference name="MK CONTRACTING" logo={ mkContractingLogo }>
                                Wij zijn dol op planten in ons kantoor, maar voorheen werden ze altijd snel slecht of
                                gingen ze dood. Mehmet en Ralph hebben ons goed geadviseerd m.b.t. de plekken in ons
                                kantoor waar planten het best tot hun recht komen. Zij hebben prachtige planten geleverd
                                en komen regelmatig langs om te bekijken hoe ze erbij staan en het nodige onderhoud te
                                doen. Wij hebben sindsdien geen dode planten meer gehad!
                            </Reference>
                        </ReferenceDeck>
                    </Container>
                </Jumbotron>

                <Jumbotron fluid
                           className="mb-0 pt-2"
                           style={ {
                               backgroundColor: "white"
                           } }
                >
                    <Container>
                        <div className="embed-responsive embed-responsive-16by9" style={ {
                            marginTop: "20px",
                            maxWidth: "560px",
                            marginLeft: "auto",
                            marginRight: "auto"
                        } }>
                            <iframe title="promotievideo" src="https://www.youtube.com/embed/Y9-eTTgM0FY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                        </div>
                    </Container>
                </Jumbotron>

            </React.Fragment>
        );
    }
}

export default References;
